const HANG_TIME_AD_UNIT_ID = 52;
const HANG_TIME_AD_UNIT_VIDEO_ID = 53;

export const getHangTimeAdUnit = ({ containsVideo }) => {
  return `In-Slot-Hangtime-Mobile${containsVideo ? "-Video" : ""}`;
};

export const getHangTimeAdUnitId = ({ containsVideo }) => {
  return containsVideo ? HANG_TIME_AD_UNIT_VIDEO_ID : HANG_TIME_AD_UNIT_ID;
}
