import InlineModal from "@components/UI/Modal/InlineModal";
import { withAlertsRender } from "@controllers/Portals";
import styled from "@emotion/styled";
import {
  faClipboardList,
  faShare,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonGroup, FalafelLoader, Input, SnackBar, Text, Hr} from "@ui-lib";
import { Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { logAnalytics } from "@utils/analyticsUtil";
import { isNumber } from "@utils/idTypeChecker";
import { getHangTimeAdUnit, getHangTimeAdUnitId } from "@services/advertisingApi";
import { CreativeStore } from "../../../store/CreativeStore";
import { generateGif } from "@services/firestore";

const SnackAlert = withAlertsRender(SnackBar);

const ShareRoot = styled.div`
  position: relative;
`;

const Share = ({
  url,
  description,
  content,
  onClick,
  buildIDs,
  isLoading = false,
  error,
  leftIcon = faShare,
  creative = null,
}) => {
  const [showShare, setShowShare] = useState(false);
  const [showCopyConfirm, setShowCopyConfirm] = useState(false);
  const [adManagerCampaignId, setAdManagerCampaignId] = useState("");
  const [isGeneratingGif, setIsGeneratingGif] = useState(false);
  const [gifResponse, setGifResponse] = useState(null);
  const showSendGif = !!creative;
  const { id: creativeId, containsVideo } = creative || {};
  const iframeVideo = CreativeStore((state) => state.iframeVideo);

  const handleShareModal = (e) => {
    onClick && onClick(e);
    setShowShare(!showShare);
  };

  const handleCopy = () => {
    logAnalytics.featureShareInteraction(buildIDs, "copy_link");
    navigator.clipboard.writeText(url);
    setShowCopyConfirm(true);
    setTimeout(() => {
      setShowShare(false);
      setShowCopyConfirm(false);
    }, 2000);
  };

  const toNewTab = () => {
    logAnalytics.featureShareInteraction(buildIDs, "open_in_new_tab");
    window.open(url, "_blank").focus();
  };

  const handleSendGif = async () => {
    setGifResponse(null);

    const payload = {
      adUnit: getHangTimeAdUnit({ containsVideo }),
      // This will always be in slot as the ad unit is always hang-time
      adProduct: "In-Slot",
      url: `https://cat.playgroundxyz.com/creative/${creativeId}`,
      // Deceiving, but this the device is set to "desktop" purely for larger aspect ratio
      device: "desktop",
      adUnitId: getHangTimeAdUnitId({ containsVideo }),
      campaignId: adManagerCampaignId,
    };
    
    setIsGeneratingGif(true);
    const gifResponse = await generateGif(payload);
    setIsGeneratingGif(false);

    setGifResponse(gifResponse.data); 
    return;
  };

  const handleClose = () => {
    setShowShare(false);
    
    // Resume video playing when we close the modal
    if (iframeVideo) {
      iframeVideo.play();
    }
  };

  useEffect(() => {
    if (error) {
      logAnalytics.errorGeneral(error, window.location.href);
      setShowShare(false);
    }
  }, [error]);

  useEffect(() => {
    if (showShare) {
      logAnalytics.featureShareOpened(buildIDs);
    }
  }, [showShare]);

  // We need to pause any videos playing as there's an issue
  // where the video graph appears to focus off modals
  // meaning users won't type into fields
  if (showShare && iframeVideo) {
    iframeVideo.pause();
  }

  return (
    <ShareRoot>
      <Button
        size="sm"
        onClick={handleShareModal}
        leftIcon={<FontAwesomeIcon icon={leftIcon} />}
      >
        {content}
      </Button>

      <InlineModal
        title={description}
        show={showShare}
        handleClose={handleClose}
      >
        {showSendGif && (
          <>
            <Input
              id="ad-manager-campaign-id-input"
              type="text"
              value={adManagerCampaignId}
              placeholder="Campaign ID"
              onChange={(e) => setAdManagerCampaignId(e.target.value)}
            />
            {isGeneratingGif ? (
              <>
                <FalafelLoader />
                <Text>
                  CAT generating and sending. This should take somewhere between
                  1-3 minutes.
                </Text>
              </>
            ) : (
              <ButtonGroup>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={handleSendGif}
                  leftIcon={<FontAwesomeIcon icon={faArrowRight} />}
                  isDisabled={!isNumber(adManagerCampaignId)}
                >
                  Send
                </Button>
              </ButtonGroup>
            )}
            {!!gifResponse?.link && (
              <>
                <Text type="body02">
                  Gif sent to your campaign successfully. Check your gif{" "}
                  <Text as="a" target="_blank" href={gifResponse.link}>
                    here
                  </Text>
                </Text>
              </>
            )}
            {!!gifResponse?.message && (
              <>
                <Text type="body02">
                  There was an error generating your gif. Please submit a{" "}
                  <Text as="a" target="_blank">
                    Media Support ticket{" "}
                  </Text>
                  with the following message:
                  {gifResponse.message}
                </Text>
              </>
            )}
            <Hr margin="2" />
            <Text type="baseline">{description}</Text>
          </>
        )}
        {url && (
          <Input
            type="text"
            value={url}
            readOnly
            onClick={(e) => e.target.select()}
          />
        )}

        <div>
          {error && (
            <SnackAlert title="Something wrong happened" status="error" />
          )}
          {showCopyConfirm ? (
            <SnackAlert title="Copied" status="success" />
          ) : !isLoading ? (
            <ButtonGroup>
              <Button
                size="sm"
                variant="primary"
                onClick={handleCopy}
                leftIcon={<FontAwesomeIcon icon={faClipboardList} />}
              >
                Copy link
              </Button>
              <Button
                size="sm"
                variant="secondary"
                onClick={toNewTab}
                leftIcon={<FontAwesomeIcon icon={faArrowRight} />}
              >
                Open in new tab
              </Button>
            </ButtonGroup>
          ) : (
            <FalafelLoader />
          )}
        </div>
      </InlineModal>
    </ShareRoot>
  );
};

export default Share;
