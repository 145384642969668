import { SnackBar, SpinnyCat, Link } from "@ui-lib";
import { LeftPanel, RightPanel, QrButton } from "./indexCreativeViewer";
import { Subheader } from "../../composites/Subheader/Subheader";
import MissingPage from "@components/UI/MissingPage/MissingPage";
import Share from "@components/UI/Share/Share";
import Favourite from "@components/UI/Favourite/Favourite";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { userMetadata } from "../../../data/userMetadata.js";
import DemoControls from "../../components/DemoControls/DemoControls";
import GUI from "../../components/GUI/GUI";
import PreviewerIFrame from "../../components/PreviewerIFrame/PreviewerIFrame";
import Graph from "../../composites/Graph/Graph";
import { CreativeStore } from "../../store/CreativeStore";
import { GUIStore } from "../../store/GUIStore";
import { MetricStore } from "../../store/MetricStore";
import { UIStore } from "../../store/UIStore";
import { useLocation } from "react-router-dom";
import useStudioBuildID from "../../hooks/useStudioBuildID";

import {
  checkForElement,
  formatSwitch,
  generatePreviewerURL,
  isVideoPlaying,
} from "../../util/creativeUtil";
import { withAlertsRender } from "../Portals";
import { logAnalytics } from "../../util/analyticsUtil";
import { Center, HStack, VStack, Text, Button } from "@chakra-ui/react";

const SnackBarAlert = withAlertsRender(SnackBar);

const CreativeViewer = (props) => {
  // LOCAL STATES
  const [qrExpand, setQrExpand] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [creativeScrolled, setCreativeScrolled] = useState(false);

  // GLOBAL STATES
  const userInfo = UIStore((state) => state.userInfo);
  const setMissingDemoCreative = UIStore(
    (state) => state.setMissingDemoCreative
  );
  const setIframeVideoProgress = UIStore(
    (state) => state.setIframeVideoProgress
  );

  const creatives = MetricStore((state) => state.creatives);
  const attentionTime = MetricStore((state) => state.attentionTime);

  const buildID = CreativeStore((state) => state.buildID);
  const sequences = CreativeStore((state) => state.sequences);
  const scrollMode = CreativeStore((state) => state.scrollMode);
  const dwellTimes = CreativeStore((state) => state.dwellTimes);
  const scriptVersion = CreativeStore((state) => state.scriptVersion);
  const domains = CreativeStore((state) => state.domains);
  const scrubBaseline = CreativeStore((state) => state.scrubBaseline);
  const dwellTimeBaseline = CreativeStore((state) => state.dwellTimeBaseline);
  const sessionSize = CreativeStore((state) => state.sessionSize);
  const videoSequences = CreativeStore((state) => state.videoSequences);
  const setFormatObject = CreativeStore((state) => state.setFormatObject);
  const setIframeVideo = CreativeStore((state) => state.setIframeVideo);
  const setBuildID = CreativeStore((state) => state.setBuildID);
  const clearBuildID = CreativeStore((state) => state.clearBuildID);
  const brandNames = CreativeStore((state) => state.brandNames);
  const campaignNames = CreativeStore((state) => state.campaignNames);
  const creativeNames = CreativeStore((state) => state.creativeNames);
  const usingAggregatedData = CreativeStore(
    (state) => state.usingAggregatedData
  );
  const errorLoadingCreative = CreativeStore(
    (state) => state.errorLoadingCreative
  );
  let creativeFormat = CreativeStore((state) => state.format); // overwriten if demo mode
  let vertical = CreativeStore((state) => state.vertical);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const iframeSrcDoc = CreativeStore((state) => state.iframeSrcDoc);
  const fetchIframeSourceDoc = CreativeStore(
    (state) => state.fetchIframeSourceDoc
  );
  const clearIframe = CreativeStore((state) => state.clearIframe);
  const detachDemoDatabase = CreativeStore((state) => state.detachDemoDatabase);
  const loadCreativeSequences = CreativeStore(
    (state) => state.loadCreativeSequences
  );
  const clearSequences = CreativeStore((state) => state.clearSequences);
  const creativeScrollProgress = CreativeStore(
    (state) => state.creativeScrollProgress
  );
  const setCreativeScrollProgress = CreativeStore(
    (state) => state.setCreativeScrollProgress
  );
  const containsVideo = CreativeStore((state) => state.containsVideo);
  const domainsList = CreativeStore((state) => state.domainsList);
  const defaultBaseline = CreativeStore((state) => state.defaultBaseline);
  const setBaseline = CreativeStore((state) => state.setBaseline);

  const minSequenceLength = GUIStore((state) => state.minSequenceLength);
  const triggerType = GUIStore((state) => state.triggerType);
  const domainWhiteList = GUIStore((state) => state.domainWhiteList);
  const creativeBlacklist = UIStore((state) => state.creativeBlacklist);
  // Hardcoding the creativeFormat for now, since the demo creative
  // doesn't have one.
  if (props.demoMode) creativeFormat = "hang-time";

  const iframeOnLoadHandler = async (e) => {
    let eTarget = e.target;
    if (!eTarget.contentWindow.document.getElementById("middle-ad")) return;
    const iframeWindow = eTarget.contentWindow;
    if (iframeWindow.length) {
      let targetFrame = iframeWindow.document
        .getElementById("middle-ad")
        .getElementsByTagName("iframe")[0];

      let format =
        targetFrame.contentWindow.xyzContext.dimension.creative.format;
      let camelCaseFormat = format.replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase();
      });
      let formatObject =
        targetFrame.contentWindow[camelCaseFormat] ||
        targetFrame.contentWindow.format;
      setFormatObject(formatObject);
      let foundVideo = false;

      const checkVideoElement = () => {
        if (containsVideo && !foundVideo) {
          let videoElement = null;
          if (formatObject.catVideo || formatObject.video) {
            foundVideo = true;
            switch (format) {
              case "hang-time":
                videoElement =
                  formatObject.catVideo ||
                  formatObject.video ||
                  formatObject.video.container.childNodes[0];
                break;
              case "video-switch":
                videoElement = formatObject.catVideo || formatObject.video;
                // overriding the regular video switch jump to long version
                // by adding a loop to the S, M or L that is being viewed.
                videoElement.loop = true;
                break;
              default:
                videoElement = null;
            }
            setIframeVideo(videoElement);

            // To keep the video progress updating smoothly in the videoChart
            // we need to set a shorter interval than the 250ms offered by
            // 'timeupdate' events.
            let videoWatcher = setInterval(() => {
              if (isVideoPlaying(videoElement)) {
                setIframeVideoProgress(
                  (videoElement.currentTime / videoElement.duration) * 100
                );
              }
            }, 25);
            targetFrame.addEventListener("beforeunload", function () {
              clearInterval(videoWatcher);
            });
          }
        }
      };
      // We need to wait for the video to be injected into the
      // creative access the video element for play/pause controls
      iframeWindow.addEventListener("scroll", (ev) => {
        iframeScrollHandler(ev);
        checkVideoElement();
      });

      // For formats other than hangtime, the load times of the nested iframes
      // can cause the viewer to check for .xyz-overlay before it has loaded.
      // checkForElement is halting the below code (controlling the scroll to
      // the creative) executing until that element is present - not an ideal
      // solution but working...
      await checkForElement(iframeWindow, ".xyz-overlay");
      const xyzOverlay =
        iframeWindow.document.body.getElementsByClassName("xyz-overlay")[0];
      const top = xyzOverlay.getBoundingClientRect().top;
      const $target = $(eTarget);
      $target.contents().children().scrollTop(800);
      let scrollDistance = 1750;
      $target.contents().children().animate(
        {
          scrollTop: scrollDistance,
        },
        1500,
        "swing"
      );

      if (containsVideo && !foundVideo && document.hidden) {
        const visibilityHandler = () => {
          if (!document.hidden) {
            console.log("Tab is in the foreground");
            checkVideoElement();
            let videoCheckCounter = 0;
            const checkVideoTimer = setInterval(() => {
              checkVideoElement();

              if (foundVideo) {
                document.removeEventListener(
                  "visibilitychange",
                  visibilityHandler
                );
                clearInterval(checkVideoTimer);
              }
              // check for video element in the creative
              if (videoCheckCounter > 20) {
                console.warn("Video not found in creative");
                document.removeEventListener(
                  "visibilitychange",
                  visibilityHandler
                );
                clearInterval(checkVideoTimer);
              }
              videoCheckCounter++;
            }, 250);
          }
        };

        document.addEventListener("visibilitychange", visibilityHandler);
      }
    }
  };

  const iframeScrollHandler = (e) => {
    const seamlessFrame = e.target
      .getElementById("middle-ad")
      .getElementsByTagName("iframe")[0];

    let frameFormat =
      seamlessFrame.contentWindow.xyzContext.dimension.creative.format;
    const format = frameFormat.includes("-")
      ? formatSwitch(
          seamlessFrame.contentWindow.xyzContext.dimension.creative.format
        )
      : frameFormat;

    // adjusted the below to .cat.timeline to support adding formats that
    // do not keep the timeline value updated on the main format object
    let progress =
      scrollMode === "timeline"
        ? seamlessFrame.contentWindow[format]
          ? seamlessFrame.contentWindow[format].timeline ||
            seamlessFrame.contentWindow[format].cat.timeline
          : seamlessFrame.contentWindow.format.timeline ||
            seamlessFrame.contentWindow.format.cat.timeline
        : seamlessFrame.contentWindow[format]
        ? seamlessFrame.contentWindow[format].progress
        : seamlessFrame.contentWindow.format.progress;
    setCreativeScrollProgress(progress);
  };

  const QRClickHandler = () => {
    logAnalytics.creativeQrExpand(buildID, !qrExpand ? "expand" : "collapse");
    setQrExpand(!qrExpand);
  };

  // LIFE CYCLE EVENTS

  // here we're forcing the buildID to always be the parent studio buildID, even if
  // the user is requesting via an adBuyId or creativeId
  const checkedBuildID = useStudioBuildID(props.match.params.creativeID);

  // Set buildID state when props changed
  useEffect(() => {
    if (props.demoMode && userInfo.loggedIn) {
      logAnalytics.demoViewed();
      const matchingUser = userMetadata.filter((userMeta) => {
        return (
          props.userEmail
            .toLowerCase()
            .includes(userMeta.email.toLowerCase()) ||
          userMeta.email.toLowerCase().includes(props.userEmail.toLowerCase())
        );
      });
      if (matchingUser.length) {
        const demoCreativeID = matchingUser[0].demoCreativeID;
        setBuildID(demoCreativeID);
      } else {
        logAnalytics.demoNoCreative();
        setMissingDemoCreative(true);
      }
    } else if (checkedBuildID) {
      setBuildID(checkedBuildID);
    }
  }, [
    props.mode,
    props.demoMode,
    props.match.params.creativeID,
    props.userEmail,
    userInfo.loggedIn,
    checkedBuildID,
  ]);

  // fetch iframe source doc when build id changes
  useEffect(() => {
    if (buildID) {
      // set title to buildID
      document.title = `CAT report - ${buildID}`;

      // Analytics - log creative viewed
      logAnalytics.creativeViewed(buildID);
      try {
        // if there is a preview ID override in query params, we'll use that
        if (queryParams.has("preview")) {
          const previewID = queryParams.get("preview");
          fetchIframeSourceDoc(previewID);
        } else {
          // otherwise we'll use the buildID as normal
          fetchIframeSourceDoc(buildID);
        }
      } catch (error) {
        setErrorMessage(error);
      }

      return () => {
        document.title = "CAT";
      };
    }
  }, [buildID]);

  // load sequences when setBuildID or minSequenceLength changes
  useEffect(() => {
    if (buildID) {
      try {
        loadCreativeSequences(
          buildID,
          minSequenceLength,
          triggerType,
          [],
          props.demoMode && userInfo.loggedIn
        );
      } catch (error) {
        setErrorMessage(error);
      }
    }
  }, [
    minSequenceLength,
    props.demoMode,
    userInfo.loggedIn,
    buildID,
    triggerType,
  ]);

  useEffect(() => {
    if (buildID) loadCreativeSequences(buildID, 1, "scrub", domainWhiteList);
  }, [domainWhiteList]);

  const baselineOverride = GUIStore((state) => state.baselineOverride);
  useEffect(() => {
    setBaseline(baselineOverride);
  }, [baselineOverride]);

  // general unmount clear useless states preventing pollution. for real!
  useEffect(() => {
    return () => {
      if (props.demoMode) detachDemoDatabase(buildID);
      clearIframe();
      clearSequences();
      clearBuildID();
    };
  }, []);

  // Any way to move these into a seperate file to not clog up this one?
  // custom hooks?
  // analytics - scrolled
  useEffect(() => {
    if (creativeScrollProgress > 50 && creativeScrolled === false) {
      setCreativeScrolled(true);
      logAnalytics.creativePreviewScrubed(buildID);
    }
  }, [creativeScrollProgress]);

  // analytics - viewed
  useEffect(() => {
    if (sessionSize > 0) {
      logAnalytics.creativeSessionsLoaded(
        buildID,
        false,
        sessionSize,
        creativeFormat ?? "unknown"
      );
    }
  }, [sessionSize]);

  // alerts and errors
  const zeroMetricError = attentionTime === 0;

  // -----------------------------------------------------
  // should be in presentation mode?
  const isPresentationMode = queryParams.get("layoutMode") === "presentation";
  // this is the URL param that is passed thorugh when gif-generator
  // is viewing the page to create a gif for the slide deck

  // check if we're manually hiding this build id
  const creativeIsBlacklisted = creativeBlacklist.includes(buildID);

  // if we are manually hiding it, and we're not in presentation mode, show a 404
  const show404 = creativeIsBlacklisted && !isPresentationMode;

  // 404 page
  if (show404) {
    console.log("Check the creative-blacklist.");
    return (
      <Center minH="90vh" w="100vw" as={VStack}>
        <Text fontSize="18px">
          This creative isn't available to view at the moment.
        </Text>
        <Button onClick={() => props.history.push("/")} mt={4}>
          Go back
        </Button>
      </Center>
    );
  }
  // -----------------------------------------------------

  return (
    <VStack
      width="100vw"
      height={isPresentationMode ? "100vh" : "calc(100vh - 50px)"}
      pt={isPresentationMode ? "0" : "50px"}
    >
      <Subheader
        display={isPresentationMode ? "none" : "flex"}
        title={
          <Text type="body02" as="span">
            Cat Report &nbsp;
            {creativeFormat && (
              <Link
                href={generatePreviewerURL(buildID, creativeFormat) || "#"}
                title="Preview this ad in a new tab"
                maxWidth="200px"
                target="_blank"
              >
                {creativeNames}
              </Link>
            )}
          </Text>
        }
        sessionSize={sessionSize}
        minSessions={5000}
      >
        <QrButton buildId={buildID} />
        <Share
          description={"Share a link to this CAT report"}
          content={"Share"}
          buildIDs={[buildID]}
          url={window.location.href}
          creative={{
            id: buildID,
            containsVideo
          }}
        />
        {userInfo.loggedIn && (
          <Favourite buildID={buildID} userID={userInfo.id} />
        )}
      </Subheader>
      <HStack
        id="creative-viewer-container"
        maxWidth={isPresentationMode ? "100%" : "var(--maxScreenWidth)"}
        alignItems="flex-start"
        width="100%"
        height="100%"
      >
        {!errorLoadingCreative && (
          <LeftPanel
            p={isPresentationMode ? 0 : undefined}
            borderRight={
              isPresentationMode ? "none" : "1px solid var(--colors-neutral-4)"
            }
          >
            {creativeFormat && (
              <PreviewerIFrame
                srcDoc={iframeSrcDoc}
                creativeFormat={creativeFormat}
                onLoadHandler={iframeOnLoadHandler}
                creativeID={buildID}
                sessionSize={sessionSize}
                attentionTime={attentionTime}
                scriptVersion={scriptVersion}
                creativeNames={creativeNames}
                brandNames={brandNames}
                campaignNames={campaignNames}
                isLoading={false}
                isLegacy={true}
              />
            )}
          </LeftPanel>
        )}
        <RightPanel>
          {/* <Text as="b" type="baseline" >{creativeNames || '\u00A0'} </Text>   */}
          {props.demoMode || creativeFormat ? (
            <>
              <Graph
                creative={{
                  buildID,
                  sequences,
                  scrollMode,
                  creatives,
                  dwellTimes,
                  scrubBaseline,
                  dwellTimeBaseline,
                  sessionSize,
                  creativeFormat,
                  videoSequences,
                  containsVideo,
                  vertical,
                  usingAggregatedData,
                }}
                adProgress={creativeScrollProgress}
                showMetric={true}
                demoMode={props.demoMode}
              />

              {props.demoMode && <DemoControls />}
            </>
          ) : errorLoadingCreative ? (
            <MissingPage />
          ) : (
            <SpinnyCat size="s" />
          )}
        </RightPanel>
      </HStack>
      {userInfo.loggedIn && (
        <GUI
          display={isPresentationMode ? "none" : "flex"}
          {...{
            domainsList: domains ? domains : [],
            dwellTimes,
            defaultBaseline,
          }}
        />
      )}
      {/* ---- ERRORS ----- */}
      {!!errorMessage && !props.demoMode && (
        <SnackBarAlert
          title={
            errorMessage
              ? `${errorMessage}`
              : `We couldn't find your chart at this time.`
          }
          status="error"
        />
      )}
      {!!props.demoMode &&
        sessionSize <= 1 &&
        !userInfo.missingDemoCreative && (
          <SnackBarAlert
            title={
              <>
                This demo creative doesn't have any recorded sessions.
                Scan&nbsp;
                <Link onClick={() => setQrExpand(!qrExpand)}>the QR code</Link>
                &nbsp; to start gathering data ↗
              </>
            }
            status="warning"
          />
        )}
      {!!props.demoMode &&
        !!userInfo.missingDemoCreative &&
        !!userInfo.loggedIn && (
          <SnackBarAlert
            title="Pssstt... you should ask Andrea to setup a demo creative for your account."
            delayToClose={8000}
            status="warning"
          />
        )}
      {zeroMetricError && (
        <SnackBarAlert
          title={
            <>
              We're having some issues fetching the metrics for this creative.
              Sorry! Please check back a little later.
            </>
          }
          status="warning"
        />
      )}
    </VStack>
  );
};

const CreativeViewerWithRouter = withRouter(CreativeViewer);

export default CreativeViewerWithRouter;
