import { mountStoreDevtool } from "simple-zustand-devtools";
import create from "zustand";
import {
  getSequences,
  fetchBaseline,
  fetchAggregatedCreativeData,
  fetchVideoBaselines,
} from "../services/firestore";
import { isCreativeOrAdBuyID } from "../util/idTypeChecker";
import {
  clearDemoData,
  detachDemoDatabase,
  getDemoSequences,
} from "../services/realtime";
import { fetchIframeSourceDoc } from "../services/studio";

const CreativeStore = create((set, get) => ({
  buildID: "",
  format: "",
  scrollMode: "timeline",
  formatObject: null,
  setFormatObject: (formatObject) => {
    set({ formatObject: formatObject });
  },
  iframeSrcDoc: "",
  iframeLoaded: false,
  iframeVideo: null,
  sequences: [],
  sessionSize: 0,
  dwellTimes: [],
  creativeScrollProgress: 0,
  scrubBaseline: [],
  dwellTimeBaseline: [],
  scriptVersion: "unknown",
  vertical: null,
  errorLoadingCreative: false,
  setIframeVideo: (iframeVideo) => {
    set({ iframeVideo: iframeVideo });
  },
  setBuildID: (buildID) => {
    set({ buildID: buildID });
  },
  setCreativeScrollProgress: (progress) => {
    set({ creativeScrollProgress: progress });
  },
  setBaseline: (baseline) => {
    set({
      scrubBaseline: get()[`${baseline}ScrubBaseline`],
      dwellTimeBaseline: get()[`${baseline}DwellTimeBaseline`],
    });
  },
  fetchIframeSourceDoc: async (buildID) => {
    try {
      const response = await fetchIframeSourceDoc(buildID);
      set({
        iframeSrcDoc: response,
      });
    } catch (error) {
      console.error("Cannot fetch the creative:", error);
      throw new Error("Cannot fetch the creative thru this build id");
    }
  },
  clearIframe: () => {
    set({
      iframeLoaded: false,
      iframeSrcDoc: "",
    });
  },
  loadCreativeSequences: async (
    buildID,
    minSequenceLength,
    triggerType,
    filterDomain = [],
    isDemo = false
  ) => {
    set({
      sequences: [],
      dwellTimes: [],
      scriptVersion: "",
      brandNames: "",
      campaignNames: "",
      creativeNames: "",
      sessionSize: "",
      format: "",
      videoSequences: {},
      containsVideo: null,
      vertical: "",
      usingAggregatedData: false,
    });

    if (isDemo) {
      getDemoSequences(buildID, (data) => {
        const values = Object.values(data);
        // use .progress if the data recorded is from format.progress
        set({
          containsVideo: null,
          scrollMode:
            values.length && values[0][0].startProgress
              ? "progress"
              : "timeline",
          sessionSize: values.length,
          sequences: values.flatMap((value) => {
            const sequences = value.length ? value : [];
            return sequences.length >= minSequenceLength ? sequences : [];
          }),
        });
      });
      return;
    }

    // currently there is no pre-aggregated data for adBuyIDs or creativeIDs
    // NOTE - temporarily forcing all builds to fetch and aggregate data from the db
    if (true) { // isCreativeOrAdBuyID(buildID)
      let sequenceData = await getSequences({
        buildID,
        minSequenceLength,
        triggerType,
        filterDomain,
        queryByCreativeID: isCreativeOrAdBuyID(buildID),
      }).catch((e) => {
        set({ errorLoadingCreative: true });
        throw new Error("Could not get sequences.");
      });
      const { format = "hang-time", containsVideo } = sequenceData.data;
      const baseline = await fetchBaseline(
        containsVideo ? `${format}-video` : format
      );
      const dwellTimeBaseline = baseline.dwellTime;
      const scrubBaseline = baseline.scrubBaseline;

      // get the vanillia videoDropOffBaselines
      const videoDropOffBaseline = containsVideo
        ? baseline.videoDropOffBaseline
        : null;
      const byDurationVideoDropOffBaselines = containsVideo
        ? await fetchVideoBaselines(format)
        : null;

      set({
        ...sequenceData.data,
        dwellTimeBaseline,
        scrubBaseline,
        videoDropOffBaseline,
        byDurationVideoDropOffBaselines,
      });
      return;
    }

    let aggregatedCreativeData = await fetchAggregatedCreativeData(
      buildID
    ).catch((e) => {
      set({ errorLoadingCreative: true });
      throw new Error("Could not get aggregated data.");
    });
    const {
      aggregatedSessionSize,
      scrubs,
      dwellTime,
      format = "hang-time",
      containsVideo,
      scriptVersion,
      videoInViewSequence,
      videoDropOff,
      aggregatedVideoSessionSize,
      domains,
    } = aggregatedCreativeData;

    const definitelyHasVideo = containsVideo && aggregatedVideoSessionSize;

    const sequences = scrubs.map((scrub, i) => ({
      timeline: i,
      playfulness: scrub / aggregatedSessionSize,
    }));

    const dwellTimes = dwellTime.map((dwell, i) => ({
      timeline: i,
      dwellTime: dwell / aggregatedSessionSize,
      counter: aggregatedSessionSize,
    }));

    let videoSequences = {};

    if (definitelyHasVideo) {
      const videoDropOffSessions = videoDropOff.reduce(
        (acc, curr) => acc + curr,
        0
      );

      let droppedOff = 0;
      videoSequences.videoDropOffSequence = videoDropOff.map(
        (dropOff, i, arr) => {
          if (i !== arr.length - 1) {
            droppedOff += dropOff;
          }

          return Math.round(
            ((videoDropOffSessions - droppedOff) / videoDropOffSessions) * 100
          );
        }
      );
      // TODO: videoSequences.videoInViewSequence at some point when we know how we want to chart them
    }

    const baseline = await fetchBaseline(
      definitelyHasVideo ? `${format}-video` : format
    );
    const dwellTimeBaseline = baseline.dwellTime;
    const scrubBaseline = baseline.scrubBaseline;
    const videoDropOffBaseline = definitelyHasVideo
      ? baseline.videoDropOffBaseline
      : null;
    const byDurationVideoDropOffBaselines = definitelyHasVideo
      ? await fetchVideoBaselines(format)
      : null;
    const creativeSequences = {
      sequences,
      dwellTimes,
      dwellTimeBaseline,
      usingAggregatedData: true,
      scrubBaseline,
      format,
      domains,
      // we'll probably add these soon
      // formatScrubBaseline: scrubBaseline,
      // formatDwellTimeBaseline: dwellTimeBaseline,
      sessionSize: aggregatedSessionSize,
      containsVideo: !!definitelyHasVideo,
      defaultBaseline: "format",
      videoSequences,
      videoDropOffBaseline,
      byDurationVideoDropOffBaselines,
      // we'll probably add these soon also
      // vertical: "",
      // verticalScrubBaseline: scrubBaseline,
      // verticalDwellTimeBaseline: dwellTimeBaseline,
      scriptVersion: scriptVersion,
    };
    set(creativeSequences);
    return;

    // We'll never need to get here when using all pre-aggregated data - just leaving for now
    let sequenceData = await getSequences({
      buildID,
      minSequenceLength,
      triggerType,
      filterDomain,
    }).catch((_) => {
      throw new Error("Could not get sequences.");
    });

    // a bit hacky atm – we don't want to overwrite the original domain list
    // if we're in the process of jumping between filter values
    if (!sequenceData.data.domainsList) {
      delete sequenceData.data.domainsList;
    }
    set(sequenceData.data);
  },
  updateChart: (data) => {
    const {
      scrubs,
      dwellTime,
      sessionCount,
      videoDropOff,
      videoInViewSequence,
      videoSessionCount,
    } = data;

    let droppedOff = 0;
    const videoDropOffSequence =
      videoDropOff && videoSessionCount
        ? videoDropOff.map((dropOff, i, arr) => {
            if (i !== arr.length - 1) {
              droppedOff += dropOff;
            }

            return Math.round(
              ((videoSessionCount - droppedOff) / videoSessionCount) * 100
            );
          })
        : [];
    set({
      sequences: scrubs.map((scrub, i) => ({
        timeline: i,
        playfulness: scrub / sessionCount,
      })),
      dwellTimes: dwellTime,
      sessionSize: sessionCount,
      videoSequences: {
        videoDropOffSequence,
      },
    });
  },
  clearSequences: () => {
    set({ sequences: [], creativeScrollProgress: 0 });
  },
  clearDemoData: (buildID) => {
    clearDemoData(buildID);
  },
  clearBuildID: () => {
    set({ buildID: "" });
  },
  detachDemoDatabase: (buildID) => {
    detachDemoDatabase(buildID);
  },
}));
if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("CreativeStore", CreativeStore);
}
export { CreativeStore };
