// checking if the buildID value is a: pxyz buildID, a pxyz creativeID or a gumgum adBuyID
function isCreativeID(buildID) {
  return buildID.length === 32;
}

export function isNumber(value) {
  // Convert the value to a number and check if it is NaN.
  return !isNaN(parseFloat(value));
}

function isAdBuyID(buildID) {
  // AdBuyIDs are numbers - other IDs are strings
  return isNumber(buildID);
}

export const isCreativeOrAdBuyID = (buildID) => {
    return isCreativeID(buildID) || isAdBuyID(buildID);
}
